import { TableHeaders } from '@/shared/components/table/models';

export const AccountHeaders: TableHeaders[] = [
  {
    key: 'type1Name',
    label: 'Type'
  },
  {
    key: 'id',
    label: 'ACCT Code'
  },
  {
    key: 'name',
    label: 'ACCT Code Name'
  }
];
