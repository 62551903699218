















































































import { Component, Vue } from 'vue-property-decorator';
import { AccountModule } from '@/store/index';
import {
  FilterInput,
  FilterConditions,
  TransformFiltersToJson
} from '@/shared/services/filter/filter.service';
import { ToastHelper } from '@/utils/toast.util';
import Loading from '@/shared/components/Loading.vue';
import { QuickBooksService } from '../../../../shared/services/quickbooks/quickbooks.service';

@Component({
  components: {
    Loading
  }
})
export default class AccountFilters extends Vue {
  accountModule = AccountModule;
  quickBooksService = QuickBooksService;
  loading = false;

  accFltrOptions = [
    { value: 'code', text: 'ACCT CODE' },
    { value: 'name', text: 'ACCT CODE NAME' }
  ];

  constructor() {
    super();
  }

  created() {
    this.search();
  }

  AccFilter = new FilterInput({
    condition: FilterConditions.BeginsWith,
    search: null,
    field: 'code'
  });

  async search() {
    this.loading = true;

    try {
      this.accountModule.setFilters(TransformFiltersToJson([this.AccFilter]));
      if (!this.AccFilter.search) {
        await this.accountModule.load();
      } else {
        await this.accountModule.search();
      }
    } catch (err) {
      ToastHelper.show('Account code search', err.message, 5000, 'danger');
    }

    this.loading = false;
  }

  async sync() {
    this.loading = true;
    try {
      await this.quickBooksService.glCodesSync(null);
      ToastHelper.show(
        'Sync GL codes',
        'Sync successfully completed',
        8000,
        'success'
      );
    } catch (err) {
      ToastHelper.show('Sync GL codes', err.message, 8000, 'danger');
    }

    this.loading = false;
  }

  resetFilters() {
    this.AccFilter = new FilterInput({
      condition: FilterConditions.BeginsWith,
      search: null,
      field: 'code'
    });

    this.accountModule.setFilters(TransformFiltersToJson([this.AccFilter]));
  }
}
